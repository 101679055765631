<template>
  <div class="wrapper">
    <div class="wrapper__card" v-if="!loadingEdit">
      <div class="wrapper__card__header">
        <div class="left">
          <!-- <small
            >here <strong>{{ hasSomeUpdate }}</strong></small
          > -->
          <div>
            <z-btn
              icon="$settings_rounded"
              primary
              small
              rounded
              text="configurar jornada"
              @click="configurationDialog = true"
            />
          </div>
          <div class="inpt">
            <v-form ref="formname">
              <z-input
                v-model="journey.name"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                :hide-details="true"
                label="Nome da Jornada"
              />
            </v-form>
          </div>

          <div class="icons">
            <v-icon :disabled="!isDisabledUndo" @click="moveStep(false)"
              >$undo_rounded</v-icon
            >
            <v-icon :disabled="!isDisabledRedo" @click="moveStep(true)">
              $redo_rounded
            </v-icon>
            <!-- <v-icon @click="undo">$undo_rounded</v-icon>
            <v-icon @click="redo"> $redo_rounded </v-icon> -->
            <!-- <v-divider vertical></v-divider>
            <v-icon> $zoom_out_rounded </v-icon>
            <v-icon>$zoom_in_rounded</v-icon>
            <v-icon>$zoom_out_map_rounded</v-icon> -->
          </div>
        </div>

        <div class="right">
          <div>
            <z-btn
              text="Salvar Jornada"
              :is-loading="updateLoading"
              small
              class="mr-2"
              rounded
              @click="updateJourneyV2"
            />
            <!--            <z-btn-->
            <!--                v-if="journey.status === 'draft'"-->
            <!--              @click="updateDraft"-->
            <!--              :is-loading="updateLoading"-->
            <!--              small-->
            <!--              rounded-->
            <!--              text="atualizar rascunho"-->
            <!--              :disabled="!journey.name"-->
            <!--            />-->
            <!--            <z-btn-->
            <!--                v-else-->
            <!--                @click="updateJourney"-->
            <!--                :is-loading="updateLoading"-->
            <!--                small-->
            <!--                rounded-->
            <!--                text="atualizar jornada"-->
            <!--                :disabled="!journey.name || !journey.audienceId || !tree"-->
            <!--            />-->
          </div>
          <div>
            <z-btn
              small
              rounded
              text="validar"
              @click="validateJourneySteps(treeTest)"
            />
          </div>
          <div>
            <z-btn
              icon="$help_outline_rounded"
              small
              rounded
              text="ajuda"
              @click="helpDialog = true"
            />
          </div>
        </div>
      </div>
      <div v-if="!isTreeLoading" class="wrapper__card__body">
        <div class="wrapper__card__body__content__center">
          <button
            v-if="data.nodes.length === 0"
            type="button"
            style="z-index: 999; position: absolute; left: 40%; cursor: pointer"
            @click="createDefaultJourney()"
          >
            Clique para adicionar uma nova jornada
          </button>
        </div>
        <div
          class="wrapper__card__body__content"
          style="padding-left: 10px"
          v-if="treeTest != null"
        >
          <TreeComponent
            v-if="treeTest != null && render"
            :database-config-id-prop="databaseConfigId"
            :nodes="treeTest"
            :key="keyComponent"
            @linkClick="clickLink"
            @yes_click="yes_click"
            @no_click="no_click"
            @node_click="nodeClick"
            @node_click_right="setNodeToRemove"
            @rename_node="setNodeToRename"
            @joinInformations="joinInformations"
          />
        </div>
      </div>

      <div v-else class="wrapper__card__body">
        <div class="d-flex align-center justify-center" style="width: 100%">
          <z-loading />
        </div>
      </div>

      <v-dialog
        v-model="configurationDialog"
        width="520"
        style="border-radius: 15px !important; z-index: 10 !important"
      >
        <ConfigurationModal
          v-if="configurationDialog"
          :timer-infos-prop="recurrencyConfiguration"
          @close="closeConfigurationModal"
        />
      </v-dialog>

      <v-dialog
        v-model="startJourneyDialog"
        width="400"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <StartJourneyModal
          v-if="startJourneyDialog"
          :is-audience-prop="isAudienceClicked"
          :father-diamond-clicked="currentDiamondOrChildDiamondClicked"
          @close="closeStartJourneyModal"
          @clicked="startJourneyClickItem"
        />
      </v-dialog>

      <v-dialog
        v-model="helpDialog"
        width="400"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <HelpModal v-if="helpDialog" @close="helpDialog = false" />
      </v-dialog>

      <v-dialog
        v-model="abtestDialog"
        width="400"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <AbTestModal
          v-if="abtestDialog"
          :clickedNode="clickedNode"
          @close="closeAbTestModal"
        />
      </v-dialog>

      <v-dialog
        v-model="configureAwaitDialog"
        width="350"
        style="border-radius: 15px !important; z-index: 99999 !important"
      >
        <ConfigureAwaitModal
          v-if="configureAwaitDialog"
          @close="inserirIntervalo"
        />
      </v-dialog>

      <v-dialog v-model="datePediodDialog" width="300">
        <DatePeriodModal v-if="datePediodDialog" />
      </v-dialog>

      <v-dialog
        v-model="selectAudienceDialog"
        width="1100"
        style="z-index: 99999 !important"
      >
        <SelectAudienceModal
          v-if="selectAudienceDialog"
          :node-prop="clickedNode"
          :audience-id-prop="journey.audienceId"
          @close="closeSelectAudienceModal"
          @editSelectedAudience="editSelectedAudience"
          @newAudience="newAudience"
        />
      </v-dialog>

      <v-dialog
        v-model="selectTemplateDialog"
        :width="templateModalWidth"
        style="z-index: 99999 !important"
      >
        <SelectTemplateModal
          v-if="selectTemplateDialog"
          :databaseConfigId="databaseConfigId"
          :typeTemplate="typeTemplate"
          :node-prop="clickedNode"
          @close="closeSelectTemplateModal"
          @changeSizeModal="changeSizeModal"
        />
      </v-dialog>

      <v-dialog
        v-model="selectPushDialog"
        :width="templateModalWidth"
        style="z-index: 99999 !important"
      >
        <SelectPushModal
          v-if="selectPushDialog"
          :node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          @close="closePushModal"
          @changeSizeModal="changeSizeModal"
        />
      </v-dialog>

      <v-dialog
        v-model="filteredAudienceDialog"
        width="800"
        style="z-index: 99999 !important"
      >
        <FilteredAudienceModal
          :dbConnectionId="databaseConfigId"
          v-if="filteredAudienceDialog"
          @close="closeFilteredAudienceModal"
        />
      </v-dialog>

      <v-dialog
        v-model="renameNodeDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <RenameNodeModal
          v-if="renameNodeDialog"
          @close="
            renameNodeDialog = false;
            nodeToRename = null;
          "
          @rename="setNewNodeName"
        />
      </v-dialog>
      <v-dialog
        v-model="cofirmationModalDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <ConfirmationLeavePageModal
          v-if="cofirmationModalDialog"
          @close="cofirmationModalDialog = false"
          @confirmLeave="closeConfirmationLeavePage"
        />
      </v-dialog>

      <v-dialog
        v-model="mensageriaCheckDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <MensageriaCheckModal
          v-if="mensageriaCheckDialog"
          :current-node-clicked-prop="clickedNode"
          :mensageriaSteps="currentMessageriaStepsItems"
          @close="closeMensageriaCheckModal"
        />
      </v-dialog>

      <v-dialog
        v-model="divisionChooseDialog"
        width="500"
        persistent
        style="z-index: 99999 !important"
      >
        <DivisionChooseModal
          v-if="divisionChooseDialog"
          @close="divisionChooseDialog = false"
          @chooseDivision="setDivisionTypeInNode"
        />
      </v-dialog>

      <v-dialog
        v-model="deleteNodeDialog"
        width="500"
        style="z-index: 99999 !important"
      >
        <ConfirmDeleteNodeModal
          v-if="deleteNodeDialog"
          @close="
            deleteNodeDialog = false;
            nodeToRemove = null;
          "
          @remove="
            nodeClickRight(nodeToRemove);
            deleteNodeDialog = false;
            nodeToRemove = null;
          "
        />
      </v-dialog>

      <v-dialog
        v-model="chooseStepToCreateDialog"
        width="400"
        style="z-index: 99999 !important"
      >
        <ChooseStepToCreate
          v-if="chooseStepToCreateDialog"
          @close="chooseStepToCreateDialog = false"
          @select="selectStepToJoin"
        />
      </v-dialog>
      <v-dialog
        v-model="draftModal"
        style="z-index: 99999 !important"
        width="500"
      >
        <ConfirmSaveDraftModal
          v-if="draftModal"
          :journey="this.journey"
          @close="draftModal = false"
          @confirm="saveDraftJourney"
        />
      </v-dialog>
      <v-dialog v-model="tagUserDialog" width="600">
        <TagUserModal
          v-if="tagUserDialog"
          :tags="stepTags"
          @close="closeTagModal"
        />
      </v-dialog>
      <v-dialog
        v-model="showModalIgnoreOptout"
        style="z-index: 99999 !important"
        width="400"
      >
        <ConfirmOptoutJourney
          v-if="showModalIgnoreOptout"
          @close="showModalIgnoreOptout = false"
          @confirm="confirmJourneyWithOptout"
        />
      </v-dialog>

      <v-dialog
        v-model="showModalRemoveUSers"
        style="z-index: 99999 !important"
        width="400"
      >
        <journeyexistingusersvalidation
          v-if="showModalRemoveUSers"
          @close="showModalRemoveUSers = false"
          @no="updateJourneyV2(true, false)"
          @yes="updateJourneyV2(true, true)"
        />
      </v-dialog>

      <v-dialog v-model="colectionNameDialog" width="600">
        <ColectionNameModal
          :actual-node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          v-if="colectionNameDialog"
          @close="closeColectionModal"
        />
      </v-dialog>

      <v-dialog
        v-model="selectWppDialog"
        :width="templateModalWidth"
        style="z-index: 99999 !important"
      >
        <SelectWppModal
          v-if="selectWppDialog"
          :node-prop="clickedNode"
          :databaseConfigId="databaseConfigId"
          @close="closeWppModal"
        />
      </v-dialog>
    </div>
    <div class="wrapper__card editLoaderCard" v-else>
      <z-loading />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { buildTreeService } from "./utils";
import { computed } from "vue";
import journeyMixin from "./journeyMixin";
import TreeComponent from "./components/fluxogram/ExampleList.vue";
import ConfigurationModal from "./components/configurationModal2.vue";
import ConfigureAwaitModal from "./components/configureAwaitModal.vue";
import StartJourneyModal from "./components/startJourneyModal.vue";
import HelpModal from "./components/helpModal.vue";
import DatePeriodModal from "./components/datePeriodModal.vue";
import TagUserModal from "./components/tagUserModal.vue";
import SelectAudienceModal from "./components/selectAudienceModal.vue";
import SelectTemplateModal from "./components/selectTemplateModal.vue";
import FilteredAudienceModal from "./components/filteredAudienceModal.vue";
import ChooseStepToCreate from "./components/chooseStepToCreate.vue";
import AbTestModal from "./components/abTestModal.vue";
import RenameNodeModal from "./components/renameNodeModal.vue";
import ConfirmDeleteNodeModal from "./components/confirmDeleteNodeModal.vue";
import MensageriaCheckModal from "./components/mensageriaCheckModal.vue";
import DivisionChooseModal from "./components/divisionChooseModal.vue";
import ColectionNameModal from "./components/colectionNameModal.vue";
import ConfirmationLeavePageModal from "./components/confirmationLeavePageModal.vue";
import { fluxoGramMock, fluxoGramMock2 } from "./data";
import "vue-light-flowchart/dist/vue-light-flowchart.css";
import ApiClient from "@/services/ApiClient";
import ConfirmOptoutJourney from "@/components/Pages/Journey/components/confirmOptoutJourney.vue";
import journeyexistingusersvalidation from "@/components/Pages/Journey/components/journeyexistingusersvalidation.vue";

import ConfirmSaveDraftModal from "@/components/Pages/Journey/components/confirmSaveDraftModal.vue";
import SelectPushModal from "@/components/Pages/Journey/components/selectPushModal.vue";
import SelectWppModal from "./components/selectWppModal.vue";
/* eslint-disable no-unused-vars */
export default {
  mixins: [journeyMixin],
  components: {
    ColectionNameModal,
    SelectPushModal,
    TreeComponent,
    ConfigurationModal,
    StartJourneyModal,
    RenameNodeModal,
    ConfirmDeleteNodeModal,
    MensageriaCheckModal,
    HelpModal,
    DatePeriodModal,
    SelectAudienceModal,
    DivisionChooseModal,
    SelectTemplateModal,
    FilteredAudienceModal,
    ConfigureAwaitModal,
    AbTestModal,
    ConfirmationLeavePageModal,
    ChooseStepToCreate,
    ConfirmSaveDraftModal,
    ConfirmOptoutJourney,
    journeyexistingusersvalidation,
    SelectWppModal,
    TagUserModal,
  },
  watch: {
    mensageriaCheckDialog(nv) {
      // sempre que o step de mensageria modal for pra false, tenho que esvaziar o array local
      // para ele não ficar inserindo informação repetida
      if (!nv) this.currentMessageriaStepsItems = [];
    },
    //     journeyStepsCount(nv) {
    //   // console.log(nv);
    //   let d = nv.map((el) => el.data);
    //   console.log(d, "watch array dados");
    // },
    startJourneyDialog(nv) {
      if (!nv) this.currentDiamondOrChildDiamondClicked = false;
    },
    arrValidationSteps(val) {
      if (val.length === 0) {
        this.journeyValidation.steps = true;
      } else {
        this.journeyValidation.steps = false;
      }
    },
  },
  data() {
    return {
      selectWppDialog: false,
      colectionNameDialog: false,
      currentMessageriaStepsItems: [],
      isToUpdateDiamondToJoin: null,
      currentDiamondOrChildDiamondClicked: false,
      currentJourneyStepIndex: -1,
      journeyStepsCount: [],
      isToUpdateDiamond: false,
      chooseStepToCreateDialog: false,
      divisionChooseDialog: false,
      mensageriaCheckDialog: false,
      firstLoadingJourney: null,
      firstLoadingTree: null,
      navigateToRoute: null,
      isSaved: false,
      isEditingSomeAudience: false,
      cofirmationModalDialog: false,
      databaseConfigId: null,
      tagUserDialog: false,
      recurrencyConfiguration: null,
      loadingEdit: false,
      journeyValidation: {
        steps: false,
        recurrency: false,
        name: false,
        templateSteps: false,
      },
      journeyRecorrenceConfigured: false,
      nodeToRemove: null,
      deleteNodeDialog: false,
      nodeToRename: null,
      renameNodeDialog: false,
      isAudienceClicked: false,
      clickedNode: null,
      arrValidationSteps: [],
      audiencesList: [],
      currentJourney: null,
      keyComponent: 0,
      nos: [],
      templatesSMSList: [],
      templatesEmailList: [],
      templatesWhatsAppList: [],
      typeTemplate: "",
      templateModalWidth: "1300",
      abtestDialog: false,
      fluxoGramMock,
      fluxoGramMock2,
      configurationDialog: false,
      startJourneyDialog: false,
      helpDialog: false,
      datePediodDialog: false,
      selectAudienceDialog: false,
      selectTemplateDialog: false,
      selectPushDialog: false,
      configureAwaitDialog: false,
      filteredAudienceDialog: false,
      http: new ApiClient(),
      rect: { x: 50, y: 50, width: 100, height: 50 },
      startPoint: { x: 50, y: 100 },
      endPoint: { x: 150, y: 100 },
      data: {
        orientation: "hor",
        centerX: 1024,
        centerY: 140,
        scale: 1,
        nodes: [],
        links: [],
      },
      clickedNodeId: null,
      rightclickedNodeId: null,
      justClickedLink: false,
      insertIndexPosition: null,
      tree: {},
      treeTest: {},
      treeGrid: [],
      newNodeFromNextOrYesClick: "next",
      journeyConfiguration: {
        init: "",
        end: "",
        repeat: false,
        runTimes: 1,
        interval: "",
        executeTime: "",
        timezone: "gmt-3",
      },
      journey: {
        name: null,
        cron: "",
        audienceId: null,
        shouldSentMensageriaToExistentContact: false,
      },
      render: true,
      lastAudience: null,
      lastAudienceType: null,
      isTreeLoading: false,
      updateLoading: false,
      isEditingJourney: true,
      showModalIgnoreOptout: false,

      showModalRemoveUSers: false,

      currentClickedNodeId: null,

      draftModal: false,
    };
  },
  provide() {
    return {
      databaseConfigId: computed(() => this.databaseConfigId),
      isToUpdateDiamond: computed(() => this.isToUpdateDiamond),
      currentClickedNodeId: computed(() => this.currentClickedNodeId),
      isToUpdateDiamondToJoin: computed(() => this.isToUpdateDiamondToJoin),
      clickedNodesList: [],
    };
  },
  async created() {
    this.loadingEdit = true;
    // await this.getAudiencesList()
    await this.getTemplatesAndAudiencesList();
    await this.getCurrentJourney();
    const _ = require("lodash");

    // INSERIR VALIDAÇÃO DE IDS, QUANDO A BRANCH DTA-86 SUBIR, ATUALIZAR AS INFORMAÇÕES PARA
    // QUANDO FOR BUSCADO A JORNADA ATUAL, TER TODAS AS INFORMAÇOES, AFIM DE COMPARAÇÃO POR ID
    const AUDIENCE_INFO = this.$store.getters["app/$currentAudienceInfo"];

    if (AUDIENCE_INFO) {
      this.journey = AUDIENCE_INFO.journey;
      this.tree = AUDIENCE_INFO.steps;
      this.treeTest = AUDIENCE_INFO.steps;
      this.data.nodes = AUDIENCE_INFO.nodes;
      this.journeyStepsCount = _.cloneDeep(AUDIENCE_INFO.stepsCount);
      this.currentJourneyStepIndex = AUDIENCE_INFO.stepsIndex;
      this.findNodeAndReplaceWithNewInfos(
        AUDIENCE_INFO.nodeClickId,
        AUDIENCE_INFO.audience
      );
    }
    this.$store.dispatch("app/RESET_CURRENT_AUDIENCE_INFO");

    // VARIAVEL SERÁ UTILIZADA PARA COMPARAR COM A JORNADA ATUAL, AFIM DE VERIFICAR ATUALIZAÇÃOES
    this.firstLoadingJourney = { ...this.journey };
    this.firstLoadingTree = _.cloneDeep(this.tree, {}, true);
    this.loadingEdit = false;
    this.validateJourneySteps(this.treeTest, false);
  },
  computed: {
    hasSomeUpdate() {
      const _ = require("lodash");

      const PASSED_IN_TREE_VERIICATION = _.isEqual(
        this.firstLoadingTree,
        this.tree
      );

      const PASSED_IN_JOURNEY_NAME_VERIFICATION =
        this.journey?.name?.length > 0 &&
        this.firstLoadingJourney?.name?.length > 0 &&
        this.journey?.name == this.firstLoadingJourney?.name;

      const PASSED_IN_JOURNEY_AUDIENCEID_VERIFICATION =
        this.journey?.audienceId?.length > 0 &&
        this.firstLoadingJourney?.audienceId?.length > 0 &&
        this.journey?.audienceId == this.firstLoadingJourney?.audienceId;

      //  console.log(this.firstLoadingTree, this.treeTest, 'here now', PASSED_IN_TREE_VERIICATION);

      return (
        !PASSED_IN_JOURNEY_NAME_VERIFICATION ||
        !PASSED_IN_JOURNEY_AUDIENCEID_VERIFICATION ||
        !PASSED_IN_TREE_VERIICATION
      );
    },
  },
  methods: {
    async createDefaultJourney() {
      const DEFAULT_JOURNEY = {
        name: "Audiência",
        imgPath: "/icons/purple-audience-icon.svg",
      };

      await this.startJourneyClickItem(DEFAULT_JOURNEY);
      this.nodeClick(this.tree.id);
    },
    async findMergedNodeAndReplaceWithFinalNode(tree, nodeId) {
      if (tree.id === nodeId) {
        if (tree.yesNextStepId && tree.YesNext == null) {
          tree.YesNext = this.generateFinalNodes();
          tree.YesNext.lastStepId = tree.id;
          tree.YesNext.lastNode = tree.id;
        }
      } else {
        if (tree.nextNode)
          await this.findMergedNodeAndReplaceWithFinalNode(
            tree.nextNode,
            nodeId
          );

        if (tree.YesNext)
          await this.findMergedNodeAndReplaceWithFinalNode(
            tree.YesNext,
            nodeId
          );
      }

      return tree;
    },
    async getCurrentJourney() {
      try {
        const _ = require("lodash");
        this.isTreeLoading = true;
        const response = await this.http.get(
          `journey/findOne/${this.$route.params.id}`
        );
        this.currentJourney = response;
        this.journey.audienceId = this.currentJourney.journey.audienceId;
        this.journey = response.journey;
        this.journeyRecorrenceConfigured =
          this.journey.cron ||
          this.journey.endsAt ||
          this.journey.nextUpdate ||
          this.journey.dupliacteSendInterval;
        this.databaseConfigId = this.audiencesList.find(
          (x) => x.id === this.currentJourney.journey.audienceId
        )?.databaseConfigId;

        if (response?.journey?.recurrencyConfiguration) {
          this.recurrencyConfiguration =
            JSON.parse(response.journey.recurrencyConfiguration) || null;
          this.recurrencyConfiguration.finishNotificationEmails =
            this.journey.finishNotificationEmails;

          this.recurrencyConfiguration.ignoreJourneys =
            this.journey.ignoreJourneys;

          this.journeyRecorrenceConfigured = this.recurrencyConfiguration;
          this.journeyValidation.recurrency = true;
        }

        const template_list_arr = _.merge(
          this.templatesEmailList,
          this.templatesSMSList,
          this.templatesPushList,
          this.templatesWhatsAppList
        );

        // necessário enviar um objeto com as seguintes chaves
        const obj = {
          status: this.currentJourney.journey.status,
          steps_array: response.steps,
          current_audience_id: this.currentJourney.journey.audienceId,
          audience_list: this.audiencesList,
          template_sms_email_arr: template_list_arr,
        };

        // const TREE = await this.buildTree(response.steps);
        const { tree, nodeListArr } = await buildTreeService(obj);

        this.data.nodes = nodeListArr;
        this.treeTest = tree;
        this.tree = tree;
        this.render = true;
        this.isTreeLoading = false;
        this.insertCurrentStepOnChange();
        this.keyComponent++;
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao buscar informações");
        this.isTreeLoading = false;
      }
      this.organizeTree();

      return;
    },
    async getTemplatesAndAudiencesList() {
      const PROMISES = [
        this.http.get("templatesConfiguration/list"),
        this.http.get("audience/list"),
      ];

      await Promise.all(PROMISES)
        .then((results) => {
          const [TEMPLATES, AUDIENCES] = results;

          this.templatesSMSList = TEMPLATES.filter(
            (d) => d.templateType === "sms"
          );
          this.templatesEmailList = TEMPLATES.filter(
            (d) => d.templateType === "email"
          );
          this.templatesPushList = TEMPLATES.filter(
            (d) => d.templateType === "push"
          );
          this.templatesWhatsAppList = TEMPLATES.filter(
            (d) => d.templateType === "whatsapp"
          );
          this.audiencesList = AUDIENCES;

          return;
        })
        .catch(() => {
          this.$toast.error("Error ao buscar templates e audiências!");
        });

      return;
    },
    async updateDraft() {
      if (this.journey.name) {
        this.updateLoading = true;
        this.journey.steps = this.tree;
        this.journey.recurrencyConfiguration = JSON.stringify(
          this.recurrencyConfiguration
        );
        this.journey.shouldSentMensageriaToExistentContact = await this.http
          .put(`journey/${this.$route.params.id}`, this.journey)
          .then((resp) => {
            this.$toast.success("Jornada atualizada com sucesso!");
            this.updateLoading = false;
            this.isSaved = true;
            this.$router.push({ name: "createdJourneysPage" });
          })
          .catch((err) => {
            this.$toast.error("Ocorreu um erro ao atualizar a sua jornada.");
            this.updateLoading = false;
            throw err;
          });
      }
    },
    async confirmJourneyWithOptout() {
      this.updateJourneyV2(true, true);
    },

    async saveDraftJourney() {
      this.loading = true;
      this.journey.steps = this.tree;
      this.journey.recurrencyConfiguration = JSON.stringify(
        this.recurrencyConfiguration
      );
      this.journey.shouldSentMensageriaToExistentContact = await this.http
        .put(`journey/${this.$route.params.id}`, this.journey)
        .then((resp) => {
          this.loading = false;
          this.isSaved = true;
          this.$toast.success("Jornada salva com sucesso!");
          this.$router.push("/journey/created");
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error("Ocorreu um erro ao tentar salvar a sua jornada.");
          throw err;
        });
    },

    async updateJourneyV2(
      ignoreOptoutValidation = false,
      ignoreremovepeopleValidation = null
    ) {
      this.journey.removeExisting = ignoreremovepeopleValidation;

      if (this.journey.lastUpdate && ignoreremovepeopleValidation == null) {
        this.ignoreremovepeopleValidation = false;
        ignoreremovepeopleValidation = false;
      }

      if (!this.journey.name) {
        this.$toast.error("Para salvar adicione um nome a sua jornada.");
      } else {
        if (this.journey.ignoreOptout && !ignoreOptoutValidation) {
          this.showModalIgnoreOptout = true;
          return;
        }
        this.doValidateJourneySteps(this.treeTest);
        if (
          this.journey.name &&
          this.journey.audienceId &&
          this.tree &&
          this.arrValidationSteps.length == 0 &&
          this.journeyRecorrenceConfigured &&
          this.journeyValidation.templateSteps
        ) {
          this.loading = true;
          this.journey.steps = this.tree;
          this.journey.recurrencyConfiguration = JSON.stringify(
            this.recurrencyConfiguration
          );

          if (this.journey.status !== "active")
            this.journey.status = "active-paused";

          this.journey.shouldSentMensageriaToExistentContact = await this.http
            .put(`journey/${this.$route.params.id}`, this.journey)
            .then((resp) => {
              this.$toast.success("Jornada atualizada com sucesso!");
              this.updateLoading = false;
              this.isSaved = true;
              this.$router.push({ name: "createdJourneysPage" });
            })
            .catch((err) => {
              this.$toast.error("Ocorreu um erro ao atualizar a sua jornada.");
              this.updateLoading = false;
              throw err;
            });
        } else {
          this.draftModal = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.editLoaderCard .zoox-loader {
  margin: 0 auto;
}

.flowchart-container {
  height: 100% !important;
  width: 100% !important;
}

#position,
.unselectable {
  display: none !important;
  color: white;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  // border: 1px solid;
  &__card {
    // border: 2px solid blue;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      padding: $z-s-1;
      background: #ffffff;
      border-radius: 15px;
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-1;
      flex-wrap: wrap;

      & > div {
        // border: 2px solid;
        // flex: 1 1 auto;
      }
      .left {
        flex: 2 1 auto;
        display: flex;

        align-items: center;
        gap: $z-s-1 0.5rem;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }

        .inpt {
          width: 100%;
        }
        .icons {
          display: flex;
          align-items: center;
          gap: $z-s-1 0.5rem;
        }

        @media (max-width: 750px) {
          flex-wrap: wrap;
        }
      }
      .right {
        // flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      border: 2px dashed $z-black-1;
      border-radius: 15px;
      padding: $z-s-1;
      max-height: 100%;
      flex: 1 1 100%;
      overflow-y: auto;
      @include trackScrollBar;
      display: flex;
      align-items: center;
      padding: 0px !important;
      padding: 0 10px !important;
      &__content {
        display: flex;
        align-items: start;
        padding-top: 35px;
        overflow: scroll;
        height: 100% !important;
        width: 100% !important;
        &__center {
          padding-top: 35px;
          align-items: center;
        }
      }
    }
  }
}
</style>
